import { useAuth } from '@frontend/auth';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { DrawerWithHeader, Logo } from '@frontend/ui-elements';
import { useMediaQuery, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  AppBar,
  Container,
  HeaderDrawerWrapper,
  Link,
  Toolbar,
} from './PlatformHeader.css';
import { HEADER_VARIANTS, PlatformHeaderProps } from './PlatformHeader.types';
import useHeader from './useHeader/useHeader';
import LogoutModal from './LogoutModal/LogoutModal';
import SuccessToast from '../Toasts/SuccessToast/SuccessToast';
import HeaderBurgerMenu from './HeaderBurgerMenu/HeaderBurgerMenu';
import HeaderDesktopMenu from './HeaderDesktopMenu/HeaderDesktopMenu';
import VariantForm from '../Forms/VariantForm/VariantForm';
import { useNavigate } from 'react-router-dom';
import useModal from '../Modals/useModal/useModal';
import CreateAdditionalRoleModal from '../Modals/CreateAdditionalRoleModal/CreateAdditionalRoleModal';
import { useUpdateOperationMode } from '@frontend/api';
import { useEffect, useState } from 'react';

export default function PlatformHeader({ variant }: PlatformHeaderProps) {
  const { isAuthenticated } = useAuth();

  const theme = useTheme();
  const intl = useIntl();
  const isTabletOrLess = useMediaQuery(theme.breakpoints.down('tabletL'));
  const navigate = useNavigate();
  const userVariantPath =
    variant === HEADER_VARIANTS.BUYER ? 'request' : 'listing';

  const {
    currentOpenElement,
    openBurgerDrawer,
    openFormDrawer,
    openDropdownMenu,
    openLogoutModal,
    openToast,
    closeElement,
    closeToast,
    isBurgerDrawerOpen,
    isFormDrawerOpen,
    isDropdownMenuOpen,
    isLogoutModalOpen,
    isToastOpen,
  } = useHeader();

  const {
    openCreateAdditionalRoleModal,
    closeModal,
    isCreateAdditionalRoleModalOpen,
  } = useModal();

  const navigateToVariantHome = () => {
    return variant === HEADER_VARIANTS.BUYER
      ? PLATFORM_ROUTES.buyerDashboard
      : variant === HEADER_VARIANTS.SELLER
        ? PLATFORM_ROUTES.sellerDashboard
        : import.meta.env.VITE_STATIC_APP_URL;
  };

  const {
    isCurrentUserBuyer,
    isCurrentUserSeller,
    isCurrentOperationModeBuyer,
    isCurrentOperationModeSeller,
    reFetchUserInfo,
  } = useAuth();

  const [_, setResetStatus] = useState(false);
  const { mutate: updateOperationMode, status: updateOperationModeStatus } =
    useUpdateOperationMode();

  useEffect(() => {
    if (updateOperationModeStatus === 'success') {
      if (reFetchUserInfo) {
        reFetchUserInfo();
      }
      setResetStatus(prev => !prev);
      navigate(
        variant === HEADER_VARIANTS.BUYER
          ? PLATFORM_ROUTES.sellerDashboard
          : PLATFORM_ROUTES.buyerDashboard,
      );
    }
  }, [updateOperationModeStatus, variant]);

  const switchOperationMode = () => {
    if (reFetchUserInfo) {
      reFetchUserInfo();
    }
    setResetStatus(prev => !prev);

    if (
      (variant === HEADER_VARIANTS.BUYER && isCurrentOperationModeBuyer) ||
      (variant === HEADER_VARIANTS.SELLER && isCurrentOperationModeSeller)
    ) {
      updateOperationMode();
    } else {
      navigate(
        variant === HEADER_VARIANTS.BUYER
          ? PLATFORM_ROUTES.sellerDashboard
          : PLATFORM_ROUTES.buyerDashboard,
      );
    }
  };

  const startOperationModeWorkflow = () => {
    if (isCurrentUserBuyer && isCurrentUserSeller) {
      switchOperationMode();
    } else {
      openCreateAdditionalRoleModal();
    }
  };

  const closeFormAndOpenToast = () => {
    closeElement();
    openToast();
  };

  return (
    <AppBar>
      <Container>
        <Toolbar id="header-toolbar">
          <Link id="user-header.link.logo" to={navigateToVariantHome()}>
            <Logo />
          </Link>
          {isTabletOrLess ? (
            <HeaderBurgerMenu
              closeElement={closeElement}
              isBurgerDrawerOpen={isBurgerDrawerOpen}
              isLogoutModalOpen={isLogoutModalOpen}
              openBurgerDrawer={openBurgerDrawer}
              openFormDrawer={openFormDrawer}
              openLogoutModal={openLogoutModal}
              switchOperationMode={startOperationModeWorkflow}
              variant={variant}
            />
          ) : (
            <HeaderDesktopMenu
              closeElement={closeElement}
              currentOpenElement={currentOpenElement}
              isDropdownMenuOpen={isDropdownMenuOpen}
              openDropdownMenu={openDropdownMenu}
              openFormDrawer={openFormDrawer}
              openLogoutModal={openLogoutModal}
              switchOperationMode={startOperationModeWorkflow}
              variant={variant}
            />
          )}

          {isAuthenticated && (
            <DrawerWithHeader
              headerLine={intl.formatMessage({
                id: `customer-platform.header.${userVariantPath}-form.heading`,
              })}
              id="user-header.form-drawer"
              onClose={closeElement}
              open={isFormDrawerOpen}
            >
              <HeaderDrawerWrapper>
                <VariantForm
                  onSubmit={closeFormAndOpenToast}
                  variant={variant}
                />
              </HeaderDrawerWrapper>
            </DrawerWithHeader>
          )}

          {isLogoutModalOpen && (
            <LogoutModal
              onClose={isTabletOrLess ? openBurgerDrawer : closeElement}
            />
          )}

          {isToastOpen && (
            <SuccessToast
              isToastOpen={isToastOpen}
              closeToast={closeToast}
              titleId={`customer-platform.shared.components.user-header.success-toast.${userVariantPath}-form.title`}
              messageId={`customer-platform.shared.components.user-header.success-toast.${userVariantPath}-form.message`}
            />
          )}

          {isCreateAdditionalRoleModalOpen && (
            <CreateAdditionalRoleModal
              isOpen={true}
              onClose={closeModal}
              onAccountUpdated={switchOperationMode}
              newRoleName={intl.formatMessage({
                id: isCurrentUserSeller
                  ? 'customer-platform.shared.company.role.buyer.name'
                  : 'customer-platform.shared.company.role.seller.name',
              })}
            />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
